<template>
  <div>
    <b-tabs content-class="mt-2" nav-wrapper-class="card pt-1 pr-1 pl-1" lazy>
      <b-tab title="Info">
        <b-row class="invoice-preview">
          <b-col cols="12" xl="12" md="12">
            <b-card no-body class="invoice-preview-card">
              <b-card-body class="invoice-padding">
                <b-row class="invoice-spacing">
                  <b-col cols="12" md="6">
                    <h6 class="mb-2">Postura:</h6>
                    <h6 class="mb-25">{{ post.code }}</h6>
                    <p class="card-text mb-25">
                      {{ post.postura_date | myGlobalDayWhour }}
                      <b-badge
                        :variant="
                          post.status == 'ANULADA'
                            ? 'danger'
                            : post.status == 'PENDIENTE'
                            ? 'warning'
                            : post.status == 'EN PROCESO'
                            ? 'info'
                            : 'success'
                        "
                      >
                        {{ post.status }}
                      </b-badge>
                    </p>
                    <p class="card-text mb-25">
                      {{ quantityEggs ? quantityEggs : 0 }} huevos
                    </p>
                    <template v-if="cintillo.cintillo_color">
                      <div class="cintillo-card2" >
                        <span style="font-size: 12px; line-height: 1.7" v-if="cintillo.cintillo_color"
                          >{{ cintillo.chickenleg_description }}
                          {{ cintillo.cintillo_description }}
                        </span>
                        <span class="fa-squaress" v-if="cintillo.cintillo_color"
                          ><feather-icon
                            :style="`color: ${cintillo.cintillo_color}; fill: ${cintillo.cintillo_color}; border-radius: 3px;`"
                            icon="SquareIcon"
                            size="20"
                        /></span>
                        <span style="font-size: 12px; line-height: 1.7"  v-if="cintillo.second_color_color"
                          >{{ cintillo.second_color_description }}
                        </span>
                        <span class="fa-squaress"  v-if="cintillo.second_color_color"
                          ><feather-icon
                            :style="`color: ${cintillo.second_color_color}; fill: ${cintillo.second_color_color}; border-radius: 3px;`"
                            icon="SquareIcon"
                            size="20"
                        /></span>
                        <b-badge
                          variant="danger"
                          class="mt-1"
                          v-if="cintillo.cintillo_pata_postura_number == 2"
                        >
                          LIBERADO
                        </b-badge>
                        <b-badge variant="success" class="mt-1" v-else>
                          COLOCADO
                        </b-badge>
                      </div>
                    </template>
                    <template v-else> Sin asignar </template>
                  </b-col>
                  <b-col
                    md="3"
                    cols="6"
                    class="mt-xl-0 mt-2 d-flex justify-content-md-end"
                  >
                    <div class="d-flex flex-column align-items-center">
                      <h6 class="mb-2">Padrillo:</h6>
                      <b-avatar
                        size="70px"
                        :src="padrillo.padrillo_thumb"
                        icon="twitter"
                        class="mb-1"
                      />
                      <strong>{{ padrillo.padrillo_alias }}</strong>
                      <small class="text-muted"
                        >@{{ padrillo.placa_padrillo }}</small
                      >
                    </div>
                  </b-col>
                  <b-col
                    md="3"
                    cols="6"
                    class="mt-xl-0 mt-2 d-flex justify-content-md-end"
                  >
                    <div class="d-flex flex-column align-items-center">
                      <h6 class="mb-2">Madrilla:</h6>
                      <b-avatar
                        size="70px"
                        :src="madrilla.madrilla_thumb"
                        icon="twitter"
                        class="mb-1"
                      />
                      <strong>{{ madrilla.madrilla_alias }}</strong>
                      <small class="text-muted"
                        >@{{ madrilla.placa_madrilla }}</small
                      >
                    </div>
                  </b-col>
                  <b-col cols="12">
                    <!-- <h5 class="title-module mt-3">Pollitos</h5> -->
                    <div class="table-responsive mt-3">
                      <table class="table tabla-pollito">
                        <thead
                          class="text-center cursor-pointer thead-class"
                          @click="extendTable()"
                          id="table-pollitos"
                        >
                          <th scope="col" class="col-md-2">
                            {{ visible ? "Cintillo ala" : "# MACHOS" }}
                          </th>
                          <th scope="col" class="col-md-2">
                            {{ visible ? "Género" : "# HEMBRAS" }}
                          </th>
                          <th scope="col" class="col-md-2" v-if="visible">
                            Lote
                          </th>
                          <th scope="col" class="col-md-2">
                            {{
                              visible ? "Fecha de nacimiento" : "# SIN ASIGNAR"
                            }}
                          </th>
                          <th scope="col" class="col-md-2" v-if="visible">
                            Placa
                          </th>
                          <th scope="col" class="col-md-2" v-if="visible">
                            Estado
                          </th>
                        </thead>
                        <b-tooltip
                          :target="'table-pollitos'"
                          triggers="hover"
                          placement="top"
                          v-if="!visible"
                        >
                          Ver más
                        </b-tooltip>
                        <tbody>
                          <tr v-if="!visible">
                            <td class="text-center" data-label="# MACHOS">
                              {{ machosQuantity }}
                            </td>
                            <td class="text-center" data-label="# HEMBRAS">
                              {{ hembrasQuantity }}
                            </td>
                            <td class="text-center" data-label="# SIN ASIGNAR">
                              {{ isNotAssignedQuantity }}
                            </td>
                          </tr>
                        </tbody>
                        <b-collapse v-model="visible" tag="tbody">
                          <tr v-if="loading">
                            <td colspan="11" class="text-center">
                              <b-spinner variant="primary" />
                            </td>
                          </tr>
                          <template v-else>
                            <tr v-for="chicken in pollitos" :key="chicken.id">
                              <td data-label="Cintillo ala" class="text-center">
                                {{ chicken.cintillo_ala }}
                              </td>
                              <td data-label="Género" class="text-center">
                                <p v-if="chicken.is_assigned_plate">
                                  {{ chicken.gender }}
                                </p>
                                <b-form-select
                                  id="lblGender"
                                  v-model="chicken.gender_number"
                                  :options="genders"
                                  required
                                  size="sm"
                                  :disabled="
                                    !isAdmin || chicken.status_number == 2
                                  "
                                  @input="onChangeGenderSelect(chicken)"
                                  v-else
                                ></b-form-select>
                              </td>
                              <td data-label="Lote" class="text-center">
                                {{ chicken.lote_code }}
                              </td>
                              <td
                                data-label="Fecha de nacimiento"
                                class="text-center"
                              >
                                <b-row>
                                  <b-col cols="12" md="9">
                                    <template v-if="!chicken.is_editing_dob">
                                      <span>{{
                                        chicken.date_of_birth | myGlobalDayWhour
                                      }}</span>
                                    </template>
                                    <template v-else>
                                      <input
                                        type="date"
                                        class="form-control form-control-sm"
                                        v-model="chicken.new_dob"
                                      />
                                    </template>
                                  </b-col>
                                  <b-col cols="12" md="3" class="text-left">
                                    <feather-icon
                                      icon="EditIcon"
                                      class="cursor-pointer"
                                      v-if="
                                        isAdmin &&
                                        !chicken.is_editing_dob &&
                                        chicken.status_number == 1
                                      "
                                      @click="editChickDob(chicken)"
                                    />
                                    <template
                                      v-if="isAdmin && chicken.is_editing_dob"
                                    >
                                      <feather-icon
                                        icon="CheckIcon"
                                        class="cursor-pointer"
                                        @click="saveNewDobToChick(chicken)"
                                      />
                                      <feather-icon
                                        icon="XIcon"
                                        class="cursor-pointer"
                                        @click="closeEditChickDob(chicken)"
                                      />
                                    </template>
                                  </b-col>
                                </b-row>
                              </td>
                              <td data-label="Placa" class="text-center">
                                <p
                                  v-if="
                                    chicken.is_assigned_plate ||
                                    !isAdmin ||
                                    chicken.status_number == 2
                                  "
                                >
                                  {{ chicken.assigned_plate }}
                                </p>
                                <b-row
                                  v-if="
                                    chicken.gender != 'SIN ASIGNAR' &&
                                    !chicken.is_assigned_plate &&
                                    isAdmin &&
                                    chicken.status_number == 1
                                  "
                                >
                                  <b-col cols="12" md="9">
                                    <input
                                      type="text"
                                      class="form-control form-control-sm"
                                      v-model="chicken.assigned_plate"
                                      @input="validatePlate(chicken)"
                                    />
                                    <span
                                      class="text-danger"
                                      v-if="
                                        !chicken.is_correct_plate &&
                                        chicken.assigned_plate
                                      "
                                      >Placa existente</span
                                    >
                                  </b-col>
                                  <b-col cols="12" md="3" class="text-left">
                                    <template>
                                      <feather-icon
                                        icon="CheckIcon"
                                        :class="[
                                          'cursor-pointer',
                                          !chicken.is_correct_plate
                                            ? 'disabled-element'
                                            : '',
                                        ]"
                                        @click="setPlacaToChick(chicken)"
                                      />
                                      <feather-icon
                                        icon="XIcon"
                                        class="cursor-pointer"
                                        @click="chicken.assigned_plate = null"
                                      />
                                    </template>
                                  </b-col>
                                </b-row>
                              </td>
                              <td data-label="Estado" class="text-center">
                                {{ chicken.status }}
                              </td>
                            </tr>
                          </template>
                        </b-collapse>
                      </table>
                    </div>
                    <!-- <div class="table-responsive">
                      <table class="table">
                        <thead
                          class="text-center clickable thead-class"
                          id="table-register-eggs"
                          @click="extendTableRegisteredEggs()"
                        >
                          <th scope="col">
                            {{
                              registeredEggsVisible
                                ? "Huevos registrados"
                                : "Total huevos registrados"
                            }}
                          </th>
                          <th scope="col" v-if="registeredEggsVisible">
                            Fecha de registro
                          </th>
                        </thead>
                        <b-tooltip
                          :target="'table-register-eggs'"
                          triggers="hover"
                          placement="top"
                          v-if="!registeredEggsVisible"
                        >
                          Ver más
                        </b-tooltip>
                        <tbody>
                          <tr v-if="!registeredEggsVisible">
                            <td
                              class="text-center"
                              data-label="Total huevos registrados"
                            >
                              {{ totalRegisteredEggs }}
                            </td>
                          </tr>
                        </tbody>
                        <b-collapse v-model="registeredEggsVisible" tag="tbody">
                          <tr v-if="loading">
                            <td colspan="11" class="text-center">
                              <b-spinner variant="primary" />
                            </td>
                          </tr>
                          <template v-else>
                            <tr
                              v-for="postura in arrayPosturaDetail"
                              :key="postura.id"
                            >
                              <td
                                data-label="Huevos registrados"
                                class="align-items-center text-center"
                              >
                                <span>{{ postura.quantity }}</span>
                                <span
                                  :class="[
                                    'badge',
                                    'bg-danger',
                                    'text-white',
                                    'pr-3',
                                    'pl-3',
                                  ]"
                                  v-if="postura.deleted_at"
                                  >ELIMINADO</span
                                >
                              </td>
                              <td
                                data-label="Fecha de registro"
                                class="text-center"
                              >
                                <span v-if="postura.egg_postura_date">
                                  {{ postura.created_by_first_name }}
                                  {{ postura.created_by_last_name }} <br />
                                  {{ postura.egg_postura_date | myGlobalDay }}
                                </span>
                              </td>
                            </tr>
                          </template>
                        </b-collapse>
                      </table>
                    </div> -->
                  </b-col>
                </b-row>
              </b-card-body>
            </b-card>
          </b-col>
        </b-row>
      </b-tab>
      <b-tab title="Lote">
        <div class="table-responsive mt-3">
          <table class="table">
            <thead class="text-center">
              <th scope="col">Lote</th>
              <th scope="col">Huevos</th>
              <th scope="col">Infértiles</th>
              <th scope="col">Nacidos</th>
              <th scope="col">Muertos</th>
              <th scope="col">Cintillo ala</th>
            </thead>
            <tbody>
              <tr v-if="loading">
                <td colspan="11" class="text-center"><spinner></spinner></td>
              </tr>
              <template v-else>
                <tr v-for="lote in arrayPosturaLoteDetail" :key="lote.id">
                  <td data-label="Lote" class="text-center">{{ lote.code }}</td>
                  <td data-label="Cantidad huevos" class="text-center">
                    {{ lote.quantity_eggs }}
                  </td>
                  <td data-label="Infértiles" class="text-center">
                    {{ lote.quantity_infertile_eggs }}
                  </td>
                  <td data-label="Nacidos" class="text-center">
                    {{ lote.quantity_born_eggs }}
                  </td>
                  <td data-label="Muertos" class="text-center">
                    {{ lote.quantity_dead_eggs }}
                  </td>
                  <td data-label="Status" class="text-center">
                    <span v-if="lote.c_ala_status == 1" class="text-danger">{{
                      lote.cintillo_ala_status
                    }}</span>
                    <span v-if="lote.c_ala_status == 2" class="text-success">{{
                      lote.cintillo_ala_status
                    }}</span>
                    <span v-if="lote.c_ala_status == 3" class="text-warning">{{
                      lote.cintillo_ala_status
                    }}</span>
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
        <div
          class="d-flex justify-content-end"
          v-if="
            !thereIsNotSettledInLotePostura &&
            cintillo.cintillo_pata_postura_number != 2 &&
            isAdmin
          "
        >
          <b-button
            size="sm"
            variant="danger"
            @click="dropChickenLegFromPostura"
            >Liberar Cintillo</b-button
          >
        </div>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
export default {
  props: {
    postura: Object,
  },
  async mounted() {
    this.getPosturaDetail();
  },
  computed: {
    machosQuantity() {
      let sum = 0;
      for (let i = 0; i < this.pollitos.length; i++) {
        if (this.pollitos[i].gender == "MACHO") {
          sum++;
        }
      }
      return sum;
    },
    hembrasQuantity() {
      let sum = 0;
      for (let i = 0; i < this.pollitos.length; i++) {
        if (this.pollitos[i].gender == "HEMBRA") {
          sum++;
        }
      }
      return sum;
    },
    isNotAssignedQuantity() {
      let sum = 0;
      for (let i = 0; i < this.pollitos.length; i++) {
        if (this.pollitos[i].gender == "SIN ASIGNAR") {
          sum++;
        }
      }
      return sum;
    },
    totalRegisteredEggs() {
      let sum = 0;
      for (let i = 0; i < this.arrayPosturaDetail.length; i++) {
        sum = sum + this.arrayPosturaDetail[i].quantity;
      }
      return sum;
    },
    thereIsNotSettledInLotePostura() {
      const result = this.arrayPosturaLoteDetail.filter(
        (lote) => lote.c_ala_status == 1
      );
      if (result.length == 0) {
        return false;
      } else {
        return true;
      }
    },
  },
  data() {
    return {
      arrayPosturaDetail: [],
      arrayPosturaLoteDetail: [],
      madrilla: {
        madrilla_alias: null,
        placa_madrilla: null,
      },
      padrillo: {
        padrillo_alias: null,
        placa_padrillo: null,
      },
      cintillo: {
        cintillo_color: null,
        cintillo_description: null,
        chickenleg_description: null,
        second_color_color: null,
        second_color_description: null
      },
      pollitos: [],
      quantityEggs: null,
      loading: false,
      visible: false,
      registeredEggsVisible: false,
      genders: [
        {
          text: "MACHO",
          value: 1,
        },
        {
          text: "HEMBRA",
          value: 2,
        },
        {
          text: "SIN ASIGNAR",
          value: 3,
          disabled: true,
        },
      ],
      post: { code: null, postura_date: null, status: null },
    };
  },
  methods: {
    validatePlate(chick) {
      axios
        .post("/api/specimens/validate", {
          plate: chick.assigned_plate,
        })
        .then((res) => {
          if (res.data.length > 0) {
            chick.is_correct_plate = 0;
          } else {
            chick.is_correct_plate = 1;
          }
        });
    },
    onChangeGenderSelect(gender) {
      this.editChick(
        gender.gender_number,
        gender.status_number,
        gender.chick_id
      );
    },
    editChick(gender, status, chick_id) {
      axios
        .post("/api/chick/update", {
          gender: gender,
          status: status,
          chick_id: chick_id,
        })
        .then((res) => {
          // alert('Actualizado');
          this.showToast(
            "success",
            "top-right",
            "Felicidades",
            "CheckIcon",
            "Pollito actualizado!"
          );
          this.getPosturaDetail(null, null);
        });
    },
    showToast(variant, position, title, icon, text) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title,
            icon,
            text,
            variant,
          },
        },
        {
          position,
        }
      );
    },
    setPlacaToChick(chick) {
      this.$swal({
        title: "Estas seguro?",
        text: "¡No podrás revertir esto!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si",
        customClass: {
          cancelButton: "btn btn-outline-danger ml-1",
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          axios
            .post("/api/chick/set-placa", {
              chicken_id: chick.chick_id,
              assigned_plate: chick.assigned_plate,
              user_id: this.currentUser.user_id,
              dob: chick.date_of_birth,
              gender: chick.gender_number,
            })
            .then((response) => {
              if (response.status == 200) {
                this.showToast(
                  "success",
                  "top-right",
                  "Felicidades",
                  "CheckIcon",
                  "Placa registrada correctamente!"
                );
                this.getPosturaDetail();
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }
      });
    },
    editChickDob(chick) {
      chick.new_dob = chick.date_of_birth;
      chick.is_editing_dob = 1;
    },
    closeEditChickDob(chick) {
      chick.new_dob = "";
      chick.is_editing_dob = 0;
    },
    saveNewDobToChick(chick) {
      this.$swal({
        title: "Estas seguro?",
        text: "¡No podrás revertir esto!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si",
        customClass: {
          cancelButton: "btn btn-outline-danger ml-1",
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          axios
            .post("/api/chick/save-new-dob", {
              chick_id: chick.chick_id,
              new_dob: chick.new_dob,
              plate: chick.assigned_plate,
            })
            .then((response) => {
              if (response.status == 200) {
                this.showToast(
                  "success",
                  "top-right",
                  "Felicidades",
                  "CheckIcon",
                  "Fecha de nacimiento editada!"
                );
                this.getPosturaDetail();
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }
      });
    },
    dropChickenLegFromPostura() {
      axios
        .post("/api/postura/drop-chickenleg", {
          postura_id: this.postura.id,
        })
        .then((res) => {
          this.$swal({
            icon: "success",
            title: "Cintillo liberado!",
            text: "Has liberado el cintillo correctamente!",
          }).then((result) => {
            if (result) {
              this.$emit("closeModal", null);
            }
          });
        });
    },
    extendTable() {
      this.visible = !this.visible;
    },
    extendTableRegisteredEggs() {
      this.registeredEggsVisible = !this.registeredEggsVisible;
    },
    getPosturaDetail() {
      this.loading = true;
      axios
        .post("/api/postura/detail", {
          postura_id: this.postura.id,
        })
        .then((response) => {
          console.log(response.data[0][0].code);
          
          this.arrayPosturaDetail = response.data[0];
          this.post.code = this.arrayPosturaDetail[0].code;
          this.post.postura_date = this.arrayPosturaDetail[0].postura_date;
          this.post.status = this.arrayPosturaDetail[0].status;
          this.madrilla.madrilla_photo =
            this.arrayPosturaDetail.length != 0
              ? this.arrayPosturaDetail[0].madrilla_photo
              : null;
            this.madrilla.madrilla_thumb =
            this.arrayPosturaDetail.length != 0
              ? this.arrayPosturaDetail[0].madrilla_thumb
              : null;
              this.padrillo.padrillo_photo =
            this.arrayPosturaDetail.length != 0
              ? this.arrayPosturaDetail[0].padrillo_photo
              : null;
              this.padrillo.padrillo_thumb =
            this.arrayPosturaDetail.length != 0
              ? this.arrayPosturaDetail[0].padrillo_thumb
              : null;
          this.madrilla.madrilla_alias =
            this.arrayPosturaDetail.length != 0
              ? this.arrayPosturaDetail[0].madrilla_alias
                ? this.arrayPosturaDetail[0].madrilla_alias
                : this.arrayPosturaDetail[0].placa_madrilla
              : null;
          this.madrilla.placa_madrilla =
            this.arrayPosturaDetail.length != 0
              ? this.arrayPosturaDetail[0].placa_madrilla
              : null;
          this.padrillo.padrillo_alias =
            this.arrayPosturaDetail.length != 0
              ? this.arrayPosturaDetail[0].padrillo_alias
                ? this.arrayPosturaDetail[0].padrillo_alias
                : this.arrayPosturaDetail[0].placa_padrillo
              : null;
          this.padrillo.placa_padrillo =
            this.arrayPosturaDetail.length != 0
              ? this.arrayPosturaDetail[0].placa_padrillo
              : null;
          this.quantityEggs =
            this.arrayPosturaDetail.length != 0
              ? this.arrayPosturaDetail[0].number_eggs_good
              : null;
          this.cintillo.cintillo_description =
            this.arrayPosturaDetail[0].cintillo_description;
          this.cintillo.cintillo_color =
            this.arrayPosturaDetail[0].cintillo_color;
        this.cintillo.second_color_description =
            this.arrayPosturaDetail[0].second_color_description;
          this.cintillo.second_color_color =
            this.arrayPosturaDetail[0].second_color_color;
          this.cintillo.chickenleg_description =
            this.arrayPosturaDetail[0].chickenleg_description;
          this.cintillo.cintillo_pata_postura_number =
            this.arrayPosturaDetail[0].cintillo_pata_postura_number;
          if (this.arrayPosturaDetail[0].postura_pollitos) {
            this.pollitos = this.arrayPosturaDetail[0].postura_pollitos;
          }
          this.arrayPosturaLoteDetail = response.data[1];
          this.$emit('set-title', response.data[0][0].code);
          
          this.loading = false;
        });
    },
  },
};
</script>

<style>
table .show {
  display: contents !important;
}
.thead-class:hover > th {
  background: #7367f0 !important;
  transition: 0.3s;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-sweetalert.scss";
</style>

